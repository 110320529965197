// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
import React, { Fragment, useEffect, useState } from "react";
import { UserAgentApplication } from "msal";
import { Loading } from "@ediframework/ui.components";
import UsersService from "../services/UsersService";
import { useAccountData } from "../context/AccountDataContext";

const LOCAL_STORAGE = "localStorage";
const SESSION_STORAGE = "sessionStorage";
const AUTHORIZATION_KEY = "Authorization";

let state = {
  launchApp: null,
  token: null,
  msalApp: null,
  fetching: false,
};

let authParams = {
  scopes: [],
  forceRefresh: false,
};

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

let msalAppConfig = {
  auth: {
    clientId: "",
    authority: "",
    redirectUri: "",
    validateAuthority: false,
    postLogoutRedirectUri: "",
  },
  cache: {
    cacheLocation: "",
    storeAuthStateInCookie: isIE(),
  },
};

const initializeConfig = () => {
  const instance = import.meta.env.VITE_AUTH_INSTANCE;
  const authority = `${instance}${import.meta.env.VITE_AUTH_TENANT}/${
    import.meta.env.VITE_AUTH_SIGNINPOLICY
  }`;

  msalAppConfig.auth.clientId = import.meta.env.VITE_AUTH_CLIENTID;
  msalAppConfig.auth.authority = authority;
  msalAppConfig.auth.redirectUri = window.location.origin;
  msalAppConfig.auth.postLogoutRedirectUri = `${window.location.origin}/loggedout`;
  msalAppConfig.cache.cacheLocation = SESSION_STORAGE;

  authParams.scopes = import.meta.env.VITE_AUTH_SCOPES.split(",");
};

const acquireToken = async (successCallback, forceRefresh = false) => {
  const account = state.msalApp.getAccount();

  if (!account) {
    const loginInProgress = state.msalApp.getLoginInProgress();
    if (!loginInProgress) {
      state.msalApp.loginRedirect(authParams);
    }
  } else {
    try {
      authParams.forceRefresh = forceRefresh;

      const tokenResponse = await state.msalApp.acquireTokenSilent(authParams);

      state.token = tokenResponse;
      if (msalAppConfig.cache.cacheLocation === LOCAL_STORAGE) {
        window.localStorage.setItem(
          AUTHORIZATION_KEY,
          "Bearer " + tokenResponse.accessToken
        );
      } else if (msalAppConfig.cache.cacheLocation === SESSION_STORAGE) {
        window.sessionStorage.setItem(
          AUTHORIZATION_KEY,
          "Bearer " + tokenResponse.accessToken
        );
      }

      if (state.launchApp) {
        state.launchApp();
      }
      if (successCallback) {
        successCallback(tokenResponse);
      }
    } catch (error) {
      if (error) {
        const loginInProgress = state.msalApp.getLoginInProgress();
        if (!loginInProgress) {
          state.msalApp.acquireTokenRedirect(authParams);
        }
        console.error(error);
      }
    }
  }
};

const authentication = {
  initialize: () => {
    initializeConfig();
    state.msalApp = new UserAgentApplication(msalAppConfig);
  },
  run: (launchApp) => {
    state.launchApp = launchApp;
    state.msalApp.handleRedirectCallback((error) => {
      if (error) {
        const errorMessage = error.errorMessage
          ? error.errorMessage
          : "Unable to acquire access token.";
        console.error(errorMessage);
      }
    });
    acquireToken();
  },
  required: (WrappedComponent, roles) => {
    return (props) => {
      const [signedIn, setSignedIn] = useState(false);

      const { accountData, setAccountData } = useAccountData();

      useEffect(() => {
        if (!signedIn) {
          acquireToken(() => {
            const { getUserAccountWithId } = UsersService();

            const claims =
              state.token.idTokenClaims ?? state.token.account.idTokenClaims;

            if (!accountData.Id && !state.fetching) {
              state.fetching = true;
              getUserAccountWithId(claims.oid).then(async (response) => {
                window.sessionStorage.setItem(
                  "AccountId",
                  response.data.Account.Id
                );

                setAccountData(response.data.Account);
              });
            }
          });
        }
      }, []);

      useEffect(() => {
        if (accountData.Id && !signedIn) setSignedIn(true);
      }, [accountData.Id, signedIn]);

      return (
        <Fragment>
          {(signedIn && <WrappedComponent {...props} />) || <Loading />}
        </Fragment>
      );
    };
  },
  signOut: () => {
    state.msalApp.logout();
  },
  clearCache: () => {
    state.msalApp.clearCache();
  },
  clearCacheAndRedirect: () => {
    state.msalApp.clearCache();
    if (msalAppConfig.auth.postLogoutRedirectUri) {
      window.location.href = msalAppConfig.auth.postLogoutRedirectUri;
    }
  },
  getToken: () => state.token,
  getAccessToken: () => state.token.accessToken,
  getIdToken: () => state.token.account.idToken,
  isLoggedIn: () => {
    if (state.token || state.msalApp.getAccount()) return true;
    else return false;
  },
  isTokenExpired: () => {
    if (state.token) {
      return state.token.expiresOn <= new Date();
    }

    return true;
  },
  acquireToken: async (successCallback, forceRefresh = false) => {
    return await acquireToken(successCallback, forceRefresh);
  },
};

export default authentication;
